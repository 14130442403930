import { template as template_4d82ecb66bb646e3837d733fdb1f4b55 } from "@ember/template-compiler";
const FKControlMenuContainer = template_4d82ecb66bb646e3837d733fdb1f4b55(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
