import { template as template_9aea4b21c5ca42b78997440919ee8ded } from "@ember/template-compiler";
const WelcomeHeader = template_9aea4b21c5ca42b78997440919ee8ded(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
